.header {
  padding: 0.5rem 0;
  background: #001529;
  transition: none;
  min-height: 65px;
}

.head-logo {
  margin-left: 85px;
}

.head-logo img {
  height: 60px;
}

#burger-menu {
  font-size: 25px;
  color: #fff;
  height: 85px;
  display: inline-block;
  float: left;
  clear: left;
}

.nav-div,
.nav-bar {
  min-height: inherit;
}

#navbar {
  height: 100%;
  z-index: 11;
  position: fixed;
  padding-top: 50px;
}

#navbar .ant-drawer-body {
  padding: 0px;
  background-color: #001529;
}

#navbar .ant-menu {
  margin-top: 15%;
}

#navbar .ant-menu-sub {
  margin-top: 0px;
}

.app-name {
  display: inline-block;
  margin: 0 10px;
  color: #ffffff;
  font-size: 25px;
  margin-left: 1em;
}

#lang-container {
  display: inline-block;
  position: absolute;
  right: 5px;
}

.lang-select {
  background-color: #001529;
}

.logout-btn {
  margin: auto;
  color: #ffffff;
}

.sidebar-top {
  padding-top: 60px;
}

/* Login styles... */
.card-container .ant-tabs-card > .ant-tabs-content {
  max-height: 400px;
  margin-top: -16px;
}

.card-container .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}

#topNavbar {
  overflow: hidden;
  background-color: #001529;
  padding: 0px 5px;
  transition: 0.4s;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}

#topNavbar a {
  float: left;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 15px;
  line-height: 25px;
  border-radius: 4px;
}

#topNavbar #logo {
  font-size: 25px;
  font-weight: bold;
  transition: 0.4s;
  margin-left: 15px;
}
#topNavbar-right {
  margin: 20px 10px 0;
  font-weight: 600;
}
.navbar-tablet-right {
  margin-top: 0px;
}
@media screen and (min-width: 700px) and (max-width: 1024px) {
  #topNavbar #logo {
    margin-left: 0px;
    padding: 20px 0;
  }
  #topNavbar-right {
    margin-top: 5px;
  }
  .tablet-login-button {
    margin-top: 20px;
  }
  .ant-radio-group {
    margin-top: 0px;
  }
  .navbar-tablet-right {
    margin-top: 10px;
  }
  .centered {
    font-size: 38px !important;
    margin-left: 20px;
  }
  #burger-menu {
    height: 68px;
    /* display: none; */
  }
  #main-container {
    margin-top: 60px;
  }
}

#topNavbar #logo img {
  height: 50px;
  transition: 0.4s;
  margin-top: 10px;
}

#topNavbar-right {
  float: right;
  display: inline-flex;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

#topNavbar-right .lang-opt {
  margin-left: 10px;
}

.round-button {
  background-color: #1890ff;
  color: #fff;
  padding: 10px;
  border-radius: 40px;
}
.welcome-container {
  background-color: #fff;
}

.car-container {
  position: relative;
  text-align: center;
  color: white;
}

.car-container .centered {
  position: absolute;
  margin-bottom: 0px;
  font-size: 60px;
  font-weight: 600;
  margin-left: 15px;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
}
.centered p {
  margin-bottom: 0;
}
.home-carousal-button {
  color: #ffff;
  background-color: #ffa600;
  font-size: 20px;
  font-weight: 600;
  border: none;
  border-radius: 15px;
  width: 150px;
  height: 50px;
}
.navikarana {
  width: 300px;
  height: 80px;
}
.home-carousal-button:hover {
  background-color: #ffb833;
}
/* .about-main-container{
    margin-left: -60px !important;
  } */
@media screen and (max-width: 1024px) {
  .topNavbar-main-logo {
    width: 100%;
    display: inline-block;
  }
  #topNavbar a {
    float: none;
  }
  .navbar-tablet-left {
    float: left;
  }
  .navbar-tablet-right {
    float: right;
    height: 40px;
  }
  .tablet-login-button {
    margin-top: 15px;
  }
  /* .about-main-container{
  margin-left: -70px !important;
} */
}
@media screen and (max-width: 580px) {
  #main-container {
    margin-top: 55px !important;
  }
.welcome-container {
overflow-x: hidden;
}
  .home-blocks {
    padding-bottom: 620px !important;
  }
  .mobile-login-button {
   font-size: 10px;
  }
  .tablet-login-button {
    margin-top: 5px;
  }
  #topNavbar {
    padding: 10px !important;
    /* height: 55px; */
  }
  .close-look-mobile {
    display: block !important;
    margin-top: 20px;
  }
  .car-container .centered {
    font-size: 25px;
    top: 23%;
    left: 50%;
    margin-left: 0;
    width: 100%;
  }
  .car-container .centered-slide3 {
    top: 81%;
  }
  .car-container .centered-slide4 {
    top: 17%;
  }

  .home-carousal-button {
    font-size: 15px;
    height: 35px;
    margin-top: 18px;
  }
  .navikarana {
    height: 55px;
  }
  #topNavbar #logo {
    margin-left: 0px;
  }
  #topNavbar a {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 2px;
  }
  #burger-menu {
    height: auto;
    font-size: 15px;
    padding: 5px;
    /* display: none; */
    /* margin-top: 7px; */
  }
  #topNavbar-right {
    margin: 0 5px;
    /* display: none; */
  }
  .user-id-mobile {
    float: left;
    padding-top: 10px;
    margin-left: 0;
    font-size: 11px;
  }
  #topNavbar-right {
    /* float: none;
    display: none; */
  }
  .lang-opt {
    float: right;
  }
  #logo {
    margin-right: 5px;
  }
  #topNavbar-right .lang-opt {
    display: inline-block;
    margin-left: 5px;
    padding-top: 5px;
  }
  #topNavbar #logo img {
    margin-top: 0;
    height: 25px;
    transition: 0.4s;
    margin-left: 4px;
    margin-right: 8px;
  }

  #topNavbar .round-button {
    padding: 4px;
  }
  .footer-logo {
    display: none;
  }
  .img-home-1,
  .img-home-2,
  .img-home-3 {
    display: none;
  }
  .mission-content-description {
    position: relative;
    margin-top: 50px !important;
  }
  .invert-top-3 {
    position: relative !important;
    font-size: 23px;
  }
  .img-home-2,
  .img-home-3,
  .img-home-1 {
    position: relative;
  }
  .is-shown,
  .botton-heading-4 {
    position: relative;
  }

  .mission-content-description {
    width: 100% !important;
    padding: 10px !important;
    left: 5%;
    margin-left: 0 !important;
    left: 0% !important;
  }
  .close-look {
    width: 100% !important;
    display: none;
  }
  .home-disc {
    height: 250px !important;
    width: 320px !important;
    top: 250px;
    margin: 10px !important;
    padding-left: 0 !important;
    left: 0 !important;
  }
  .circle {
    height: 90% !important;
    left: -200px !important;
  }
  .invert-top {
    font-size: 30px !important;
    font-weight: 600 !important;
  }
  .invert-bottom {
    font-size: 25px !important;
  }
  .invert-bottom-second {
    font-size: 25px !important;
  }
  .botton-heading {
    padding-top: 0 !important;
  }
  .mission-content {
    margin: 0 10px;
  }
}
@media screen and (min-width: 700px) and (max-width: 1030px) {
  .img-home-1,
  .img-home-2,
  .img-home-3 {
    height: 600px !important;
  }
  .img-home-2{
    max-width: 45%;
    left: 105px;
  }
  
  .mission-content {
    height: 0px;
  }
  .mission-content-description {
    margin-top: 250px !important;
  }

  .mission-content-description {
    padding-left: 0% !important;
  }
  .home-disc {
    padding-left: 0% !important;
    height: 250px !important;
  }
  .invert-top {
    font-size: 35px !important ;
  }
  .invert-bottom {
    font-size: 45px !important;
  }
  .mission-content {
    height: 225px !important;
  }
}
/* Logiin styles end */

@media only screen and (max-width: 700px) {
  .head-logo {
    margin-left: 10px;
  }

  .app-name {
    margin: 0 15px;
    font-size: 14px;
    display: contents;
  }

  .head-logo img {
    height: 50px;
  }

  .logout-btn {
    display: none;
  }
}

.img-slide {
  height: 50px;
}

.ant-modal-content {
  background-color: #f0f2f5;
}

.white-text {
  color: #ffffff;
}
/* --------------------- */

#authContainer {
  background-color: #fff;
}
.home-blocks {
  padding-bottom: 110px;
}
.Program-heading {
  font-weight: 600;
}
.circle {
  border-radius: 50%;
  left: 0;
  opacity: 0.1;
  top: 91px;
  padding: 50%;
  position: absolute;
  width: 80px;
  z-index: 0;
}
.circle-1 {
  background-color: #eb9700;
}
#authContainer {
  padding: 0 !important;
}
.divider-heading {
  margin: 10px auto 3%;
  width: 50%;
}
.divider-line-title {
  border-top: 1px solid #b7b7b7;
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 15px);
}
.divider-arrow-title {
  border: 1px solid #b7b7b7;
}
.mission-content {
  height: 323px;
}
.vision-Heading {
  text-align: center;
  padding: 20px;
  font-size: 23px;
  width: 100%;
}
.invert-top {
  font-size: 45px;

  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 1.6%;
}
.invert-top-mnn {
  color: #1078ab;
}
.invert-top-mss {
  color: #009746;
}
.invert-top-mhh {
  color: #a65e48;
}
.botton-heading {
  max-width: 800px;
  padding-top: 20px;
}
.botton-heading-1 {
  border-bottom: 5px solid #eb9700;
}
.invert-bottom {
  padding-top: 20px;
  color: #2e2e2e;
  font-weight: 600;
  line-height: 0.99;
  padding-bottom: 0.3%;
  font-size: 80px;
}
.invert-bottom-second {
  font-size: 60px;
}
.img-home-1 {
  float: right;
  height: 800px;
  max-width: 35%;
  position: relative;
  z-index: 1;
}
.img-home-2 {
  float: right;
  height: 800px;
  max-width: 50%;
  left: 115px;
  position: relative;
  z-index: 1;
}

.img-home-3 {
  float: right;
  height: 800px;
  max-width: 60%;
  position: relative;
  z-index: 1;
}
.invert-top-3 {
  position: absolute;
}
.intro-heading {
  color: #2e2e2e;
  margin-bottom: 3%;
  font-size: 50px;
  font-weight: 600;
}
.divider-line-left {
  display: inline-block;
  vertical-align: top;
  width: calc(17% - 15px);
}
.divider-line-left-1 {
  border-top: 1px solid #eb9700;
}

.mission-content-description {
  clear: left;
  float: left;
  left: 12.5%;
  margin-left: -12.5%;
  padding-left: 14.5%;
  position: absolute;
  margin-top: 350px;
  width: 64%;
}
.divider-arrow {
  display: inline-block;
  height: 15px;
  margin: -7px 2px 0;
  -webkit-transform: rotate(138deg) skewX(20deg) skewY(20deg);
  -ms-transform: rotate(138deg) skewX(20deg) skewY(20deg);
  transform: rotate(138deg) skewX(20deg) skewY(20deg);
  vertical-align: top;
  width: 15px;
}
.divider-arrow-1 {
  border: 1px solid #eb9700;
}
.divider-line-right {
  display: inline-block;
  vertical-align: top;
  width: calc(83% - 15px);
}
.divider-line-right-1 {
  border-top: 1px solid #eb9700;
}
.body-region {
  display: block;
  font-weight: 700;
  line-height: 1.4;
  margin: -1% 0 1.6%;
  text-transform: uppercase;
}
.body-region-1 {
  color: #eb9700;
}
.home-disc {
  height: 350px;
  display: block;
  float: left;
  left: 33.5%;
  margin-left: -27.5%;
  padding-left: 8.5%;
  position: absolute;
  width: 75%;
}
.content-disc {
  padding-top: 10px;
}
.close-look {
  clear: right;
  float: right;
  padding-right: 6.5%;
  padding-left: 10px;
  position: relative;
  width: 32%;
  z-index: 1;
  margin-top: 3%;
}
.close-look-mobile {
  display: none;
}
.top-disc {
  color: #fff;
  display: block;
  font-size: 14px;
  border-radius: 28px;
  font-weight: 700;
  line-height: 1;
  padding: 5% 8%;
  text-transform: uppercase;
  text-align: center;
}
.top-disc-1 {
  background-color: #eb9700;
}

.know-more-1 {
  padding: 10px;
  text-align: center;
}
.circle-2 {
  background-color: #00ff2b;
}
.is-shown-2 {
  opacity: 1;
  width: 100%;
}
.is-shown {
  position: absolute;
  width: 100%;
}
.botton-heading-2 {
  border-bottom: 6px solid #009746;
}
.divider-line-left-2 {
  border-top: 1px solid #009746;
}
.divider-arrow-2 {
  border: 1px solid #009746;
}
.divider-line-right-2 {
  border-top: 1px solid #009746;
}
.body-region-2 {
  color: #009746;
}
.top-disc-2 {
  background-color: #009746;
}
.top-disc-2:hover {
  background-color: #027a3a;
}
.know-more-2 {
  padding: 20px 10px;
}
.circle-3 {
  background-color: #1078ab;
}
.divider-line-left-3 {
  border-top: 1px solid #1078ab;
}
.divider-arrow-3 {
  border: 1px solid #1078ab;
}
.divider-line-right-3 {
  border-top: 1px solid #1078ab;
}
.body-region-3 {
  color: #1078ab;
}
.top-disc-3 {
  background-color: #1078ab;
}
.top-disc-3:hover {
  background-color: rgb(2, 93, 138);
}
.botton-heading-3 {
  border-bottom: 6px solid #1078ab;
}
.circle-4 {
  background-color: #a65e48;
}
.top-disc-4 {
  background-color: #a65e48;
}
.top-disc-4:hover {
  background-color: rgb(145, 82, 62);
}
.botton-heading-4 {
  border-bottom: 6px solid #a65e48;
  padding-top: 90px;
  position: absolute;
  width: 100%;
}
.divider-line-left-4 {
  border-top: 1px solid #a65e48;
}
.divider-arrow-4 {
  border: 1px solid #a65e48;
}
.divider-line-right-4 {
  border-top: 1px solid #a65e48;
}
.body-region-4 {
  color: #a65e48;
}
.know-more-intro {
  text-align: center;
  margin-top: 5px;
}
.focus-container {
  background-color: #001529;
  color: #ffffff;
  margin-top: 20px;
  text-align: center;
}
.welcome-focus::before,
.focus-content::after {
  content: "";
  width: 50%;
  height: 0.4rem;
  position: absolute;
  background: grey;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2rem;
}
.focus-content::after {
  margin: 70px 0 20px;
}
.welcome-focus::before {
  margin-top: 10px;
}
.focus-content {
  margin-top: 20px;
  font-size: 24px;
  padding: 20px 20px 75px 20px;
}
.focus-empty {
  background-color: #001529;
  margin-top: 20px;
}
.welcome-focus {
  padding-top: 30px;
  font-size: 26px;
  font-weight: 500;
}

.ant-menu-item-selected {
  background-color: transparent !important;
}

.profile_drop_container {
  display: inline;
  float: right;
}

.profile_drop,
.profile_drop:hover,
.profile_drop:focus {
  background: none;
  border: none;
  color: #fff;
}

.temp-exam-center-list {
  font-size: 20px;
  margin: 20px;
  text-align: center;
  font-weight: 700;
}

.overflow-table {
  overflow-y: auto;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  display: inline-block;
  margin: 0px 2px;
}

.dot.aprv, .dot.in-prog {
  background-color: #1890ff;
}

.dot.den, .dot.pend {
  background-color: #ff182b;
}

.dot.done {
  background-color: #52c41a
}

@media screen and (min-width: 300px) and (max-width: 930px) {
  .img-home-1,
  .img-home-2,
  .img-home-3 {
    display: none !important;
  }
  .close-look{
    margin-top: 450px;
  }

  .profile_drop_container {
    display: none;
  }
}

#serverDown {
  color: #fff;
    text-align: center;
    background-color: brown;
}


#applyHint {
  color: #fff;
  text-align: center;
  background-color: rgb(43, 174, 17);
}

#topNavbar button.videobtn {
background-color: transparent;
border:none;
color: darkblue;
}