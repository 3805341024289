.ant-drawer-body {
  padding-top: 10px;
}

.row-selection tr {
  cursor: pointer;
}

#stud_prof_list_container .ant-form-item,
#profile-container .ant-form-item,
#academics_container .ant-form-item {
  margin-bottom: 0px;
}

#stud_prof_list_container .ant-card-body {
  padding: 15px;
}

#profile-container .ant-input[disabled],
#profile-container .ant-picker.ant-picker-disabled,
#profile-container
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border: 0px;
}

#profile-container .ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.85);
}

#profile-container .ant-picker.ant-picker-disabled .ant-picker-suffix,
#profile-container .ant-select-disabled .ant-select-arrow {
  display: none;
}

#academics_container .ant-card-body {
  padding: 10px;
}

td.pending {
  color: #1890ff;
}

td.denied {
  color: #ff6d18;
}

td.failed {
  color: rgb(250 16 16);
}

.marks-details-table {
  margin-bottom: 10px;
}

.marks-details-table thead th {
  font-weight: bold;
}

.expenses-table th,
.expenses-table td {
  border: 1px solid #dddddd;
  padding: 5px;
}

.expenses-table td {
  text-align: left;
}

.expenses-table tr td.money {
  text-align: right;
  cursor: pointer;
}

.scrolable-list {
  max-height: 300px;
  overflow: auto;
}

.marks-table .actions button {
  padding: 0px 5px;
}

.expenses-summ td{
  overflow-wrap: anywhere;
}