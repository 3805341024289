.newSchoolForm{
    padding-top: 20px;
}
.addSchoolSubmit{
    float: right;
    margin-bottom: 20px;
}
.add-school-heading{
    padding:25px 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}