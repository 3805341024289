 .prev-btn,  .next-btn {
    position: absolute;
    top: 35vh;
    z-index: 2;
    font-size:5em;
}

.prev-btn a:hover,  .next-btn a:hover {
    background-color: #1890ff;
    color:#fff;
}
 
 .prev-btn {
    left: 10px;
}

 .next-btn {
    right: 10px;
}

.image-view-btns {
    float: right;
    clear: right;
}

.image-view-btns button {
    margin-left: 10px;
    margin-right: 10px;
}

.image-pin {
    padding: 0px;
}

.ofc-icon {
    font-size: 170px;
}