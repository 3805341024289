.FaqContainer{
    padding: 20px;
}
.Faq-main-heading{
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #464141;
}
.faq-main-heading-box{
    border: 1px solid #d9d9d9;
}
.Faq-heading-1{
    text-align: left;
    font-weight: 16px;
    font-weight: 600;
    padding: 10px 0;
    color: #000000;
}
.Faq-heading-2{
    text-align: left;
    font-weight: 16px;
    font-weight: 600;
    padding: 10px 0;
    color: #000000;
}