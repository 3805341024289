
.footer-container{
    display: inline-block;
    width: 100%;
    background-color: #001529  ;
    border-top: 2px solid grey;
    
  }
  .map-location{
      margin: 20px;
      height: 150px; 
      overflow: hidden;
  }
  .footer-address {
      margin: 10px;
      color: #fff;
      font-size: 16px;
      text-align: center;
  }
  .address-section{
    margin: 75px 10px 10px;
    color: #fff;
    font-size: 16px;
  }
  .address-section-logo{
    margin: 75px 10px 10px;
    padding:20px 0;
    color: #fff;
    font-Size: 30px;
    float: right;
  }
  .footer-fallow-us{
    margin: 10px;
    color: #fff;
    font-size: 16px;
  }
  .footer-phno{
    padding:15px;
    float: left;
  }
  .footer-logo{
      height: 55px;
      margin: 120px;
  }
  @media screen and (min-width: 700px) and (max-width: 1030px){
    .logo-col{
     display: none;
    } 
    .footer-phno{
      padding: 25px 0 0 5px;
      font-size: 12px;
    }
    .address{
      padding-left: 20px 0;
      font-size: 12px;
    }
    .footer-logo{
      margin: 20px 35px;
    }
    .footer-logo-text{
      font-size: 11px !important;
      padding-top: 10px;
    }
    .footer-phone-mail-logo{
      padding: 20px 0 !important;
    }

  }
  @media screen and (max-width: 580px){
    .address-section{
      margin-top: 10px;
    }
    .footer-logo{
      display: block;
      height: 35px;
      margin: 0 20px 0 35px;

    }
    .address-section-logo{
      margin: 10px;
    }
    .footer-social {
      padding-left: 0;
    }

    .footer-logo-text{
      margin: 25px 0 0 20px !important;
      text-align: left !important;
      font-size: 14px !important;
      }
      .footer-phone-mail-logo{
        padding:15px !important;
      }
  }
  .footer-social{
     padding-left: 15px;
     color: #ffff;
  }
  .footer-copyright{
      color: white;
      text-align: center;
  }
  .footer-copyright{
    border-top: 2px solid grey;
    height: 70%;
    
    right: 0px;
    top: 15%;
  }
  .footer-logo{
    margin-top: 25px;
    margin-bottom: 10px;
    text-align: center;
  }
  .footer-logo-text{
    color: #ffff;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
  }
  .footer-phone-mail-logo{
    font-size: 30px;
    padding: 15px 0;
    float: right;
  }