@import '~antd/dist/antd.css';

.inline-block {
  display: inline-block;
}

#main-container {
	/* margin-left: 80px; */
	margin-top: 80px;
	transition: 0.2s;
}
@media screen and (max-width: 580px) {
    #main-container{
		margin-left: 0px;

        margin-top: 120px ;
    }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;

}

::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}