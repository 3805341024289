.status-card .ant-card-body {
  padding: 0px;
}

.status-chip {
  height: 75px;
  width: 85px;
  color: #ffffff;
  margin: 15px;
  font-size: 14px;
  background-color: #2388e7;
}

.status-hover-chip:hover {
  background-color: #06325c;
}
.status-count {
  font-size: 24px;
}

/* .status-chip.total {
    background-color: #002140;
}

.status-chip.incomp {
    background-color: #CE3D0F;
}
.status-chip.new {
    background-color: #4781F7;
}
.status-chip.verif {
    background-color: #3A4CE7;
}
.status-chip.accpt {
    background-color: #319A09;
}
.status-chip.wait {
    background-color: #6A78F3;
}
.status-chip.rejecd {
    background-color: #F5493E;
}
.status-chip.rejecc {
    background-color: #B72016;
}
.status-chip.apprv {
    background-color: #2F9712;
}
.status-chip.down {
    background-color: #E73AC5;
} */

.app-search-form .ant-form-item,
.app-staff-form .ant-form-item {
  margin-bottom: 10px;
}
.enrolment-search {
  margin-left: 20px;
}

.mg-l-l {
  margin-left: 10px;
  float: right;
}

.mg-l,
.mg-left {
  margin-left: 10px;
}

.pd-l-l {
  padding-left: 10px;
}

.row-selection tr {
  cursor: pointer;
}

.image-card {
  overflow: hidden;
}

.image-card img {
  width: 90px;
  height: 90px;
  margin: 0 25px;
}

.image-card .ant-card-body {
  padding: 5px;
}

.image-card .ant-card-head-title {
  padding: 5px 0;
}

.status-summary-container .status-card {
  margin-top: 10px;
  margin-bottom: 10px;
}

.school-summary-container .status-card {
  margin-bottom: 10px;
}

.expanded-view {
  margin: 5px;
  height: 150px;
  width: 150px;
}

.expanded-view .ant-card-head {
  padding: 0 5px;
}
.pdf-img {
  height: 90px;
}

.exam_centers .ant-table-cell,
.exam_centers .ant-table-column-sorters {
  padding: 5px;
}

.exam-center-container,
.exam_documents_container {
  padding: 10px;
}

.exam-center-container .ant-card-body,
.container {
  padding: 15px;
}

.exam-center-container .ant-card,
.container .ant-card {
  margin-top: 15px;
}

.ant-modal-body,
.ant-modal-footer {
  background-color: #ffffff;
}

.primary {
  color: #1890ff;
}

.rooms-list .ant-list-item {
  padding: 0px;
}
.exam_center_select .ant-select-selection-item {
  white-space: normal;
  line-height: 20px !important;
}

.exam_center_select .ant-select-selector {
  height: auto !important;
  min-height: 32px;
}

.hidden {
  display: none;
}

#ssc-marks-container,
#mct-marks-container {
  width: 700px;
  margin: auto;
}

#mct-marks-container {
  margin-top: 20px;
}

#upload-marks-sheet-container .ant-form-item {
  margin-bottom: 12px;
}

#doc-upload {
  width: 800px;
  margin: auto;
}

#doc-upload .ant-page-header {
  padding-bottom: 0px;
}

#reviwer-form .ant-form-item {
  margin-bottom: 10px;
}

.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]) {
  border-right-color: #1890ff;
  background-color: #1890ff;
  color: #ffffff;
}

.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-left::after {
  border-top: 1px solid #ddcdcd;
}

.ant-divider-vertical {
  background-color: #ddcdcd;
}

#reviewer-form .ant-steps-item-content {
  width: auto;
  min-height: 35px;
  /* min-height: calc(100% - 80px); */
}

.reviewer-form .ant-card-head {
  border-color: #06325c;
}

.reviewer-form .ant-card {
  margin-bottom: 20px;
}

/* .reviewer-form .ant-card-body {
  min-height: 400px;
} */

.reviewer-form .ant-form-item {
  margin-bottom: 5px;
}

.steps-action button {
  margin-left: 10px;
}

.steps-action button:first-child {
  margin-left: 0px;
}

.image-viewer .ant-card-body {
  min-height: 90px;
  padding: 10px;
}

.image-viewer .ant-card-cover {
  overflow: hidden;
  height: 100px;
}

.image-viewer .ant-card-meta-title {
  white-space: break-spaces;
}

.attch-viewer {
  display: inline-block;
}

.rotate-0, .rotate-180, .rotate-360 {
  max-height: 80vh;
  max-width: 100vh;
  margin: auto;
  display: block;
}

.rotate-90, .rotate-270 {
  max-height: 100vh;
  max-width: 80vh;
  margin: auto;
  display: block;
  position: relative;
  top: 10vh;
}

#selection-committee-container .ant-table-column-sorters {
  padding: 10px 5px;
}

.inline-input {
  width: 50%;
  margin: 0 0 2px 15px;
  height: 22px;
  float: right;
}

.view-survey-card label {
  font-weight: 700;
}

.group-1 {
  box-shadow: 0 3px 0 rgba(0, 0, 0, 0.16), 0 3px 0 rgba(0, 0, 0, 0.23);
}
.survey-comparision-container th {
  width: 30%;
}

.survey-comparision-container th:first-child {
  width: 10%;
}

#compare-survey .ant-drawer-body {
  padding-top: 0px;
}

.comparision-table .ant-card-head {
  display: none;
}

.comparision-table th,
.comparision-table td {
  border: 1px solid #06325c;
  text-align: center;
}

.comparision-table th {
  position: sticky;
  top: 0px;
  background: #1890ff;
  z-index: 2;
}

.comparision-table a {
  color: #fff;
}

.comparision-table .view-survey-card .ant-card-body {
  text-align: left;
}

.comparision-table .image-viewer .ant-card-body {
  display: none;
}

.ant-card-bordered {
  border-color: #000;
}

.comparision-table .ant-card-bordered {
  border: none;
}

.comparision-table .ant-select-selection-placeholder {
  color: #000;
}

.house-types .ant-select-selection-item {
  white-space: normal;
  line-height: 20px !important;
}

.house-types .ant-select-selector {
  height: auto !important;
  min-height: 32px;
}

#reviewers-details .ant-input[disabled] {
  color: #000;
}
