#hall-tckt-container {
    border: 1px solid #000000;
    width: 650px;
    height: auto;
    margin: auto;
    margin-top: 20px;
    padding: 10px;
}
#HallticketlogoImg{
    width: 85px;
    height: 75px;
}

#hall-tckt-container .heading {
    font-weight: 500;
    text-decoration: underline;
    font-size: 15px;
    margin-left: 65px;
}
.hall-ticket-container{
    margin-top: 20px;
    word-break: break-all;
}
.print-left-container{
    word-break: break-all;
    padding-right: 10px;
}
.hall-ticket-image img{
    width: 180px;
    height: 180px;
    float: right;
}

#hall-tckt-container .container {
    margin-top: 20px;
}

#hall-tckt-container label {
    font-weight: bold;
}

@media print {
    body {
        margin: 0mm 0mm 0mm 0mm;
    }
    #hall-tckt-container {
        width: 550px;
        margin: 0mm 0mm 0mm 0mm;
    }
    .hall-ticket-image img{
        width: 225px;
        height: 225px;
        float: right;
    }
    #topNavbar, .no-print {
        display: none;
    }
    #hall-tckt-container .hall-ticket-image img{
        width: 120px;
        height: 120px;
    }
    #hall-tckt-container .exam-center-container{
        margin-top: 20px;
        margin-left: -10px !important;
    }
    #hall-tckt-container .heading{
        margin-left: 35px;
    }
    
 }