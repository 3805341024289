.ant-card-body {
    padding: 10px;
}

.ant-form-item {
    margin-bottom: 10px;
}

/* .med-req-approval-container label {
    font-weight: bold;
    width: 50px;
    margin-right: 10px;
} */

#taskDetailsDrawer {
    margin-top: 85px;
    height: calc(100% - 85px);
}

.division {
    margin-bottom: 10px;
}

.division .ant-card-head {
    min-height: 35px;
}

.division .ant-card-head-title {
    padding: 5px 0;
}