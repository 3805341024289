.modal-form-submit-button {
    margin-left: 10px;
    margin-right: 10px;
}

.modal-form-button-div {
    text-align: right;
}

.ant-table {
    overflow-x: auto;
}

.form-container {
    min-height: 200px;
}