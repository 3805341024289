.verifyImage{
    float: left;
    height: auto;
    width: 200px;
    height: 150px;
}
.verifyImagetext{
    margin: 10px;
    color: #FFF;
    height:auto;
    overflow:auto;
}
.verifyImagefirst{
    padding-top: 20px;
}
.verifyTopText{
    padding: 20px 0;
}
.verifyImageTopText{
    padding-top: 20px;
    text-align: center;
}
.text{
    font-size: 16px;
    font-weight: 400;
    color: black;
}
.formDisc{
    font-weight: 500;
}

.medha-h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 600;
    text-decoration: underline;
}
.img-view-disc{
    padding-top: 7.5%;
}
.app-no-view{
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}