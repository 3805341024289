
.ant-form-item-label label {
    white-space: pre-wrap;
    height: 40px;
  }
  /* .ant-radio-group{
    padding-top: 10px;
  } */
  .uploadDocs{
    text-align: center;
    width: 100%;
    padding: 20px 0;
  }
  .thankNote{
    padding-top: 20px;
    text-align: center;
    font-weight: 500;
  }
  .thankNoteBottom{
    padding-top: 20px;
  }
  .exam-center{
    width:259px !important;
  }
  .img-instructions{
    font-weight: 500;
    color: blue;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 150px;
  }
  .ant-select-item-option-content {
    white-space: normal;
  }

  .exam_center_select .ant-select-selection-item {
    white-space: normal;
    line-height: 20px !important;
  }

  .exam_center_select .ant-select-selector {
    height: auto !important;
    min-height: 32px;
  }