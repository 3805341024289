@-webkit-keyframes ticker {
	0% {
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	  visibility: visible;
	}
  
	100% {
	  -webkit-transform: translate3d(-100%, 0, 0);
	  transform: translate3d(-100%, 0, 0);
	}
  }
  
  @keyframes ticker {
	0% {
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	  visibility: visible;
	}
  
	100% {
	  -webkit-transform: translate3d(-100%, 0, 0);
	  transform: translate3d(-100%, 0, 0);
	}
  }
  
.ticker-wrap {
  
	position: fixed;
	z-index: 200;
	bottom: 0;
	opacity: 0.7;
	width: 100%;
	overflow: hidden;
	height: 4rem;
	background-color: #000000; 
	padding-left: 200%;
	box-sizing: content-box;
}
	.ticker {
  
	  display: inline-block;
	  height: 4rem;
	  line-height: 4rem;  
	  white-space: nowrap;
	  padding-right: 100%;
	  box-sizing: content-box;
  
	  -webkit-animation-iteration-count: infinite; 
			  animation-iteration-count: infinite;
	  -webkit-animation-timing-function: linear;
			  animation-timing-function: linear;
	 -webkit-animation-name: ticker;
			 animation-name: ticker;
	  -webkit-animation-duration: 30s;
			  animation-duration: 30s;
	}
	.ticker-item {
  
		display: inline-block;
  
		padding: 0 2rem;
		font-size: 2rem;
		color: white;   
  
	  }
