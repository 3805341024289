.gallery-container{
 padding: 20px;
 margin: 20px;
}

    
  .card {
    /* margin-top: 5rem; */
    display: grid;
    place-items: center;
    margin: 0 10px;
    height: 250px;
    display: inline-block;
    position: relative;
    overflow: hidden;
  }
  
  .card:hover .card-reveal-left {
    transform: translateX(-100%);
  }
  
  .card:hover .card-reveal-up {
    transform: translateY(-100%);
  }
  
  .card:hover .card-reveal-right {
    transform: translateX(100%);
  }
  
  .card h2 {
    text-align: center;
    color: #fff;
    margin: 0;
    font-size: 25px;
    font-weight: 700;
  }
  
  .card-content {
    display: block;
    background: #fff;
    /* color: #000; */
  }
  
  .card-reveal {
    position: absolute;
    
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease;
  }
  .card-title{
      padding-top: 105px;
  }
  .card-reveal-1{
    background:linear-gradient(to bottom right, #43e6d8 , rgb(200, 201, 200)); 
  }
  .card-reveal-2{
    background:linear-gradient(#43e6d8 , rgb(200, 201, 200)); 
  }
  .card-reveal-3{
    background:linear-gradient(to top right, rgb(200, 201, 200),#43e6d8 ); 
  }


  .about-years{
      font-size: 17px;
      font-weight: 600;
  }
  .about-content{
      padding-bottom: 25px;
  }
  .medha-player{
    height: 300px;
    width: 500px;
    float: right; 
    margin: 15px;
    border: none;
  }
  @media screen and (max-width: 580px) {
    .medha-player{
        width: 100%;
        height: 200px;
        margin: 10px 0;
    }

}