.short-cut-tile {
  margin-bottom: 8px;
}

.shot-cuts {
  height: 9em;
  font-size: 14px;
  line-height: 90px;
  background: #0092ff;
  border-radius: 4px;
  color: #fff;
  text-align: center;
}

.short-cut-logo {
  font-size: 40px;
  display: block;
  height: 30px;
}

.short-cut-logo .anticon {
  font-size: 40px;
  color: #fff;
}
