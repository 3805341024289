.upload-by-cat .ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
}

.upload-by-cat .MuiImageListItem-root {
    height: 100px;
    border: 1px solid #000;
    margin: 2px;
}

.upload-by-cat span.MuiBox-root {
    display: block;
    text-align: center;
}

/* .upload-by-cat .MuiImageListItem-item {
    width: 125px;
    height: 125px;
} */

.upload-by-cat .MuiImageListItem-imgFullWidth {
    top: 0%;
    width: auto;
    margin: auto;
    height: 100%;
}

.upload-by-cat .MuiImageListItem-imgFullHeight {
    left: 0%;
    width: 100%;
}

.rotate-0,
.rotate-180,
.rotate-360 {
  max-height: 80vh;
  max-width: 100vh;
  margin: auto;
  display: block;
}

.rotate-90,
.rotate-270 {
  max-height: 100vh;
  max-width: 80vh;
  margin: auto;
  display: block;
  position: relative;
  top: 10vh;
}
