.slide-container {
    width: 100%;
    margin: auto; }
  
  h3 {
    text-align: center; }
  
  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 700px 400px;
    background-position: 50%;
    height: 300px;
  }
  
  .each-slide span {
    padding: 20px;
    font-size: 20px;
    text-align: center;
    color: #ffffff;
  }
  
  .each-fade {
    display: flex;
  }
  
  .each-fade .image-container {
    width: 100%;
    overflow: hidden;
  }
  
  .each-fade .image-container img {
    width: 100%;
  }
  
  .each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
  }

  .site-card-border-less-wrapper {
    background: #ececec;
    padding: 30px;
  }

  .card-95 {
      width: 95%;
      margin: auto;
  }

  .mg-l {
      margin: 10px;
  }

  .prog-logo {
      height: 100px;
      margin-right: 10px;
      margin-bottom: 10px;
  }

  @media only screen and (max-width: 700px) {
    .prog-logo {
        height: 50px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
  }

.steps-action {
  float: right;
}

.pd-l {
  padding: 10px;
}

.full-width {
  width: 100%;
}
.incomplete-text{
  font-size: 15px;
  text-align: center;
  padding-top: 25px;
  font-weight: 500;
}
/* .mss-apply{
  top: 15%;
  /* left: 25%; 
} */
.app-no-disc{
  font-size: 17px;
  padding-bottom: 10px;
}
.app-no{
  font-weight: 700;
}

.reject-text {
  width: 80%;
  margin: auto;
}

.reject-text label {
  font-weight: bold;
}
.status-text{
  text-align: center;
  font-size: 16px;
  padding: 20px;
}
.student-loggedinn-pic{
  height: 400px;
}
@media screen and (min-width: 700px) and (max-width: 1024px){
  .student-loggedinn-pic{
    height: 300px;
  }
  .mss-apply{
  top: 15%;
}
.prog-logo{
  height: 50px;
}
}
@media screen and (max-width: 580px) {
  .student-loggedinn-pic{
    height: 140px;
    margin-top: 45px;
  }
  .mss-apply{
    float: right;
  }
}