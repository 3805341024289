.add-user-button{
    float: right;
    margin-top: 10px;
}
.add-user-heading{
text-align: center;
padding-top: 10px;
font-size: 18px;
font-weight: 500;
}

.site-card-wrapper {
    background: #ececec;
    padding: 15px;
  }
/* --------------- */


.curly-quotes:before, .curly-quotes:after {
  display: inline-block;
  vertical-align: top;
  height: 30px;
  line-height: 48px;
  font-size: 50px;
  opacity: .2;
}

.curly-quotes:before {
  content: '\201C';
  margin-right: 4px;
  margin-left: -8px;
}

.curly-quotes:after {
  content: '\201D';
  margin-left: 4px;
  margin-right: -8px;
}
.quote-by{
  float: right;
}

.quote-text {
  font-size: 1.5em;
  margin: auto;
  width: 70%;
  padding-top: -40px;
}
.quote-background{
  background-image: url("../../assets/images/Wave-6.png");
  /* background-image: repeating-linear-gradient(red, yellow 10%, green 20%); */

  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover; 
}
/* ------------ */

.dashboard{
  width: 100px;
  height: 100px;
  margin: 10px 5px;
  border-radius: 10px;
  border: 2px solid #07233d;
  background-color:white;
  color: #0e355a;
}
.dashboard:hover{
  background-color: #0e355a;
  color: white;
}
.dashboard-disc{
  text-align: center;
  width: 100%;
  padding: 10px 5px;
  font-size: 15px;
  font-weight: 600;
  border-bottom: 2px solid #0e355a;

}
.dashboard-value{
  text-align: center;
  width: 100%;
  padding: 0 5px;
  font-size: 30px;
  font-weight: 600;
  border-top: 2px solid white;
}

.status-card {
  width: auto;
  display: inline-block;
}

.status-card-container {
  text-align: center;
}

.status-card .ant-card-body {
  padding: 15px;
}

#sys-config-container {
  width: 60%;
  margin: auto;
  margin-top: 20px;;
}

.shot-cuts {
  height: 90px;
  font-size: 14px;
  line-height: 90px;
  background: #0092ff;
  border-radius: 4px;
  color: #fff;
  text-align: center;
}

.short-cut-tile {
  margin-bottom: 8px;
}

.wrapped_select .ant-select-selection-item {
  white-space: normal;
  line-height: 20px !important;
}

.wrapped_select .ant-select-selector {
  height: auto !important;
  min-height: 32px;
}
@media screen and (max-width: 580px) {
  .quote-mobile-display{
    display: none;
  }
}

.notesCss {
  -webkit-text-fill-color: red;
  font-weight: 600;
  border-bottom: none;
  margin-left: 5vw;
  line-height: 0%;
}