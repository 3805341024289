
  /* #achievements{
    margin-left: -80px !important;
  } */
  #achievements .heading {
    text-transform: uppercase;
    font-size: 2.5rem;
    margin: 3rem;
    text-align: center;
    color: #333;
    position: relative;
  }
  
  #achievements .heading::after {
    content: "";
    width: 50%;
    height: .5rem;
    position: absolute;
    bottom: -2rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2rem;
  }
  #achievements .heading-mss::after{
    background: #5de09a;
  }
  #achievements .heading-mnn::after{
    background: #abd3ee;
  }
  
  #achievements .card-wrapper {
    /* max-width: 20rem; */
    margin: 0 20px;
    align-items: center;
    align-content: center;
    flex-direction: column;
  }
  
  .card-achievements {
    width: auto;
    height: auto;
    min-height: 250px;
    border-radius: 45px;
    background-color: #ebeef8;
    background-size: 400%;
    animation-name: shiny;
    animation: shiny 40s linear infinite;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    margin: 2rem 0;
    box-shadow: .5rem .5rem 3rem rgba(0,0,0, .3);
  }
   #achievements .card-mss{
    background-image: linear-gradient(25deg, #E6FFEA 0%, #5de09a 50%,#53ce8c 51%,#8cf8be 100%);

  }
  #achievements .card-mnn{
    background-image: linear-gradient(25deg, #ebf1f6 0%,#abd3ee 50%,#89c3eb 51%,#d5ebfb 100%);

  }
  
  @keyframes shiny {
    0% {
      background-position: 0 0;
    } 50% {
      background-position: 400% 0;
    } 100% {
      background-position: 0 0;
    }
  }
  
 #achievements .card-achievements .card-img {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 78%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 78%, 0% 100%);
  }
  
  .profile-img {
    width: 15rem;
    height: 15rem;
    border: 4px solid #ebeef8;
    object-fit: cover;
    border-radius: 50%;
    margin: 1rem;
    z-index: 1;
  }
  .achievements-img{
    position: absolute;
  }
  .achievements-disc{
    position: relative;
    margin-left: 17rem;
    margin-right: 2rem;
}
@media screen and (max-width: 580px) {
  .achievements-img{
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .achievements-disc{
    position: relative;
    margin-left: 5px;
    margin-right: 5px;
  }
  .gideon{
    text-align: center;
  }
  .job-title{
    text-align: center;
  }
  #achievements .heading{
    font-size: 1rem;
    font-weight: 700;
  }
  #achievements .heading::after{
    width: 100%;
    height: 0.5rem;
  }
  .profile-img{
    height: 10rem;
    width: 10rem;
  }
  .card-achievements h1{
    font-size: 1.5rem !important;
  }
}

  .card-achievements h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 0;
  }
  
  .card-achievements h1.gideon {
  
    color: #FCFFCC;
    text-shadow: 1px 5px 3px rgba(0,0,0, 0.2);
  }

  
  #achievements.job-title {
    color: #666;
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
  }
  
  .about-achievements {
    font-size: 1 rem;
    margin: 1.5rem 0;
    font-style: italic;
    text-align: center;
    color: #333;
    padding: 0 .4rem;
  }
  
  