:root {
  --black: #151515;
  --gray100: #ddd;
  --blue: #92d6e4;
  --chat-tail-size: 21px;
}

#messages-container {
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 1rem;
  padding-right: 1rem;
}

#interaction-container {
  /* width: 50%; */
  /* left: 25%; */
  position: relative;
  border-radius: 20px;
  border: 4px solid rgb(179, 176, 176);
  background-color: #fff;
  padding: 1rem;
  margin-top: 10px;
}
.chat {
  justify-items: start;
  /* width: 100% !important; */
  position: relative;
}
.chat-message {
  position: relative;
  padding: 0.625rem;
  margin-bottom: 1rem;
  width: fit-content;
  background-color: #3BB9FF;
  border-radius: 0.625rem;
  color: #fff;
}

.my-message {
  float: right;
  width: 100%;
}

.me {
  background-color: #0092ff;
  display: inline-block;
  float: right;
}
.chat-message .me {
  justify-self: end;
}
.chat-text {
  max-width: 28ch;
}
.chat-message::before,
.chat-message::after {
  content: "";
  position: absolute;
}
.chat-message::before {
  height: calc(var(--chat-tail-size) * 1.2);
  width: calc(var(--chat-tail-size) * 0.85);
  background-color: #3BB9FF;
  border-radius: 50% 50% 20px/10px 0;
  top: calc((100% - (var(--chat-tail-size) / 2)) - 11px);
  right: calc((100% + (var(--chat-tail-size) / 2) * -1) + 4px);
  left: auto;
}
.chat-message::after {
  height: calc(var(--chat-tail-size) * 1.25);
  width: var(--chat-tail-size);
  background-color: #ffffff;
  border-radius: 0 0 16px/14px;
  top: calc((100% - (var(--chat-tail-size) / 2)) - 11px);
  right: 100%;
  left: auto;
}
.me::before {
  border-radius: 50% 50% 0 20px/10px;
  left: calc((100% + (var(--chat-tail-size) / 2) * -1) + 3px);
  right: auto;
  background-color: #0092ff;
}
.me::after {
  border-radius: 0 0 0 16px/14px;
  left: 100%;
  right: auto;
}
.chat-user-name {
  font-size: 0.7rem;
  margin-bottom: 10px;
  color: #fff;
}
.chat-tim-stamp {
  font-size: 0.7rem;
  margin-bottom: 5px;
  color: #fff;
}
.chat-text-message {
  font-size: 1rem;
  margin-bottom: 10px;
}
.chat-text {
  max-width: 60ch;
  line-height: 1;
  font-weight: 500;
}
.chat-text-input-box {
  position: relative;
}
.input-text-control {
  position: relative;
}
.paper-clip-button,
.toggle-show-hide,
.send-message-button {
  margin: 10px 20px 10px 0;
  border: none;
}
.chat-close-btn {
  float: right;
  margin: 10px;
}
.input-cont-row {
  display: inherit;
  margin: 0 10px;
}
