.mss-prog-disc{
    /* margin-top: 15px; */
    font-size: 15px;
    padding-left: 10px;
}
.mss-start-word{
    font-size: 18px;
    font-weight: 500;
}
.mss-highlights{
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-top: 15px !important;
}
.highlights-heading{
    font-size: 22px;
    padding: 15px;
    font-weight: 600;
    text-align: center;
    /* text-decoration: underline; */
}
.highlight-start{
    font-size: 18px;
    font-weight: 600;
    color: black;
}
.mss-interm{
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
.mss-grad-head{
    text-align: center;
    padding-left: 10px;
    font-size: 18px;
    font-weight: 600;
    text-decoration: underline;
}
.prog-disc{
    line-height: 2em;
    font-size: 16px;
    font-weight: 500;
    padding-left: 10px;
}
.mss-disc-heading{
    font-size: 22px;
    padding: 15px 0;
    font-weight: 600;
    display: inline-block;
}
.mss-divider{
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.mhh-bar-chart{
    width: 600px;
    height: auto;
    float: right;
    padding-left: 60px;
}
/* .mvu-heading{
    font-size: 24px;
    font-weight: 600;
} */
/* .mhh-mainmain-containe, .mss-mainmain-container, .mnn-mainmain-container{
    margin-left: -70px !important;
    background-color: #ffff;
} */
/* .mss-main-container, .mhh-main-container, .mnn-main-container{
    margin-left: -70px !important;
  } */
@media screen and (max-width: 580px) {
    .mhh-bar-chart{
        width: 100%;
    }
    .dotted-lines-mnn, .dotted-lines-mhh, .dotted-lines-mss{
        border-left: none !important;
    }

}

.mhh-bar-chart svg{
    overflow: visible;
}
.mnn-pie-chart{
    width: 600px;
    float: right;
    padding: 20px 100px ;
}
.mnn-pie-chart svg{
    overflow: visible;
}
@media screen and (max-width: 580px) {
    .mnn-pie-chart{
        padding: 0px 25px;
        width: 100%;
        height: auto;

    }
}
.colored-heading{
    display: inline-block;
    padding: 13px 15px 12px;
    font-size: 18px;
    line-height: 20px;
    margin: 0;
    position: relative;
}
.colored-heading:after {
    content: '';
    border-style: solid;
    border-width: 22px 0 23px 20px;
    width: 0;
    height: 0;
    position: absolute;
    right: -20px;
    top: 0;
}
.dotted-lines-mss{
    border-left: 3px dashed #8fe638;
    margin-top: 10px;
}
.dotted-lines-mvu{
    border-left: 3px dashed #f7bd42;
    margin-top: 10px;
}

.colored-heading-mss{
    background: #8fe638; 
    margin-bottom: 20px;
}
.colored-heading-mss:after{
    border-color: transparent transparent transparent #8fe638;

}
.colored-heading-mvu{
    background:  #f7bd42; 
}
.colored-heading-mvu:after{
    border-color: transparent transparent transparent #f7bd42;

}

.colored-heading-mnn{
    background: #52c2fa;
    margin-bottom: 20px;
}
.colored-heading-mnn:after{
    border-color: transparent transparent transparent #52c2fa;

}
.dotted-lines-mnn{
    border-left: 3px dashed #52c2fa;
    margin-top: 10px;
}

.colored-heading-mhh{
    background: #e29d88;
    margin-bottom: 20px;
}
.colored-heading-mhh:after{
    border-color: transparent transparent transparent #e29d88;

}
.dotted-lines-mhh{
    border-left: 3px dashed #e29d88;
    margin-top: 10px;
}